import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Advantages from "../components/Advantages"
import SpecialFeatures from "../components/SpecialFeatures"
import Options from "../components/Options"
import Development from "../components/Development"
import ImagesGallery from "../components/ImagesGallery"
import Footer from "../components/Footer"
import Contacts from "../components/Contacts"
import Addresses from "../components/Addresses"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Теплая, круглосуточная автомойка самообслуживания в Новоалтайске."
      description="Пена - это теплая, круглосуточная автомойка самообслуживания премиум класса в Новоалтайске."
    />
    <Advantages />
    <SpecialFeatures />
    <Options />
    <Development images={data.images.nodes} />
    <ImagesGallery images={data.images} />
    <Addresses />
    <Contacts />
    <Footer />
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
    images: allFile(
      filter: { name: { regex: "/photoamplua_.*/" } }
      sort: { fields: relativePath, order: ASC }
    ) {
      nodes {
        childImageSharp {
          fluid(maxHeight: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
