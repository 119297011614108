import React from "react"
import Img from "gatsby-image"
import YouTube from "react-youtube"
import styled from "styled-components"
import DevelopmentVideo from "./DevelopmentVideo"

const H2 = styled.h2`
  margin: 40px 0px;
`

export default () => (
  <section id="development">
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-8">
          <H2>Фотогалерея</H2>
        </div>
      </div>
    </div>
  </section>
)
