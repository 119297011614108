import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TextLoop from "react-text-loop"

const Slide = styled.li`
list-style: none;
font-size: 32px;
width: 200px;
`

const Title = styled.span`
font-size: 60px;
font-weight: bolder;
line-height: 65px;

.super-big {
  font-size: 82px;
}

small {
    font-size: 32px; 
}

strong {
    font-size: 32px; 
}

@media (max-width: 400px) {
    font-size: 20px;
    line-height: 35px;
    .super-big {
        font-size: 22px;
    }

    small {
        font-size: 14px; 
    }

    strong {
        font-size: 14px; 
    }
}
`

export default ({ }) => (
    <section id="banner_3" className="wow fadeInUp" data-wow-delay="0.3s">
        <div className="container">
            <div className="flexslider_2">
                <ul className="slides">
                    <Slide>
                        <TextLoop interval={3000}>
                            <div><Title>Работаем <br /><span className="super-big">24/7</span><br /> <small>без перерывов и выходных</small> <br /> <strong>БЫСТРО И ЧИСТО</strong></Title></div>
                            <div><Title>Помощь <br />и консультация<br /> специалистов<br /> <strong>БЫСТРО И ЧИСТО</strong></Title></div>
                        </TextLoop>
                    </Slide>
                </ul>
            </div>
        </div>
    </section>
)
