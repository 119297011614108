import React from "react"
import styled from "styled-components"

const H2 = styled.h2`
margin: 20px;
color: #ddd;
`

const Ol = styled.ol`
list-style-type: decimal;
li {
    padding-bottom: 10px;
}
`

const Wrapper = styled.div`
padding: 60px;
@media (max-width: 400px) {
    padding: 30px;
}
`

export default () => (
    <section className="bg-dark text-white" id="rules">
        <div className="container">
            <div className="row d-flex items-align-center justify-content-center">
                <Wrapper className="col-lg-8">
                    <H2>Описание опций</H2>
                    <Ol>
                        <li><strong>Вода</strong> - вода под давлением для удаления грязи и пены.</li>
                        <li><strong>Моющий расствор</strong> - для удаления стойких загрязнений активным моющим средством под высоким давлением.</li>
                        <li><strong>Пена</strong> - нанесение активной пены на кузов автомобиля для удаления стойких загрязнений с выдержкой 1-2 минуты.</li>
                        <li><strong>Воск</strong> - для блеска и защиты лакокрасочного покрытия.</li>
                        <li><strong>Гидролак</strong> - покрытие автомобиля нанорасствором с высоким гидрофобным эффектом, придает более глубокий блеск и цвет кузову авто.</li>
                        <li><strong>Осмос</strong> - деминерализированная вода, финишный этап мойки для предотвращения появления пятен и разводов при высыхании автомобиля.</li>
                        <li><strong>Горячая вода</strong> - удаление грязи и пены водой под давлением в зимний период.</li>
                        <li><strong>Пылесос</strong> - чистка салона от грязи и пыли, размещен на улице.</li>
                        <li><strong>Вода для рук</strong> - вода для мытья рук и сопутствующих протирочных средств для автомобиля (находится напротив пылесоса).</li>
                    </Ol>
                    <H2 >Запрещено</H2>
                    <Ol>
                        <li>Протирка транспортных средств внутри бокса.</li>
                        <li>Мойка двигателя.</li>
                        <li>Прослушивание музыки на высокой громкости.</li>
                    </Ol>
                </Wrapper>
            </div>
        </div>
    </section>
)