import React from "react"
import styled from "styled-components"
import {Link} from "gatsby"

export default () => (
    <footer id="footer-wide">
        <div className="container">
            <div className="col-md-8">
                2017-2020 © Сеть автомоек самообслуживания <Link to="/">«ПЕНА»</Link>.<br />
                <span style={{fontSize: 13}}>Вся информация, представленная на сайте является ознакомительной.</span><br />
                <Link to="privacy" target="_blank" style={{fontSize: 13}}>Правовая информация.</Link>
            </div>

            <div className="col-lg-6 col-2 col-sm-2 col-md-2 feedback">
                {/* <a className="social inst" href="https://www.instagram.com/leyka_vlz/" target="_blank"></a> */}
                <a className="social vk" href="https://vk.com/public197250441" target="_blank"></a>
                {/* <a className="social fb" href="https://www.facebook.com/leykafranchise" target="_blank"></a> */}
		</div>

            <div className="col-lg-6 col-2 col-sm-2 col-md-2">
                <div id="dev_asgard">
                    <a href="https://plusmarketing.ru" title="Design, web, marketing">
                        plusmarketing.ru
                    </a>
                </div>
            </div>
        </div>

        <a href="#" className="btn-top" title="Вернуться наверх"><i className="fa fa-chevron-up"></i></a>
    </footer>
)


