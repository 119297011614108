import React from "react"
import Img from "gatsby-image"
import YouTube from 'react-youtube';
import styled from "styled-components"
import DevelopmentVideo from "./DevelopmentVideo"
import { YMaps, Map, Placemark } from 'react-yandex-maps'

const H2 = styled.h2`
margin: 40px 0px;
`

const Address = styled.div`
font-size: 32px;
@media (max-width: 400px) {
    font-size: 22px;
  }
`

export default () => (
    <section id="map_wrapper" className="wow fadeInUp" data-wow-delay="0.3s">
        <div className="title_white">
            <div className="container">
                <h2>Адрес</h2>
                <img src="/images/title_bgwhite.png" />
                <Address>
                    г.Новоалтайск, ул.Первостроителей 12
                </Address>
                <p>Авторазвязка Бийск-Барнаул-Новосибирск</p>
            </div>
        </div>

        <YMaps>
            <div>
                <Map defaultState={{ center: [53.385811, 83.992335], zoom: 15 }} width="100%" height="500px">
                    <Placemark geometry={[53.385811, 83.992335]} />
                </Map>
            </div>
        </YMaps>

    </section>
)