
import React from "react"
import Logo from "./Logo"
import styled from "styled-components"

const H2 = styled.h2`
margin: 40px 0px;
`

export default () => (
    <section id="onlylogo">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6 welcome_text">
                    <p>общие вопросы<br />и обратная связь</p>
                    <div className="clear"></div>
                    <a href="mailto:k409892@yandex.ru ">k409892@yandex.ru</a>
                </div>
                <div className="d-none d-md-block col-lg-6 col-md-6 welcome_logo">
                    <a href="index.html#" className="btn-top-logo"><div style={{width: 124}}><Logo /></div></a>
                </div>
            </div>
        </div>
    </section>
)