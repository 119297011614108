import React from "react"

export default () => (
  <section id="advantages" className="wow fadeInUp" data-wow-delay="0.3s">
    <div className="title_white">
      <div className="container">
        <h2>У НАС ВЫ ПОЛУЧИТЕ</h2>
        <img src="/images/title_bgwhite.png" />
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-6 col-lg-4 col-sm-4 col-md-4 ">
          <a>
            <img className="one" src="/images/preim_1a.jpg?1" />
            <img className="two" src="/images/preim_1.jpg?1" />
          </a>
          <p className="desc">
            Мойка автомобиля за 50 руб. Ежедневно с 0:00 до 6:00.
          </p>
        </div>
        <div className="col-6 col-lg-4 col-sm-4 col-md-4">
          <a>
            <img className="one" src="/images/preim_3a.jpg?1" />
            <img className="two" src="/images/preim_3.jpg?1" />
          </a>
          <p className="desc">
            Комфортные условия для мытья автомобиля в любое время года
          </p>
        </div>

        <div className="col-6 col-lg-4 col-sm-4 col-md-4">
          <a>
            <img className="one" src="/images/preim_7a.jpg?1" />
            <img className="two" src="/images/preim_7.jpg?1" />
          </a>
          <p className="desc">
            Воспользуйтесь автокосметикой в зоне протирки автомобиля
          </p>
        </div>

        <div className="col-6 col-lg-4 col-sm-4 col-md-4">
          <a>
            <img className="one" src="/images/preim_6a.jpg?1" />
            <img className="two" src="/images/preim_6.jpg?1" />
          </a>
          <p className="desc">Эффективное и бережное удаление загрязнений</p>
        </div>

        <div className="col-6 col-lg-4 col-sm-4 col-md-4">
          <a>
            <img className="one" src="/images/preim_5a.jpg?1" />
            <img className="two" src="/images/preim_5.jpg?1" />
          </a>
          <p className="desc">
            Наши специалисты всегда помогут с выбором программы мойки авто
          </p>
        </div>

        <div className="col-6 col-lg-4 col-sm-4 col-md-4">
          <a>
            <img className="one" src="/images/preim_2a.jpg?1" />
            <img className="two" src="/images/preim_2.jpg?1" />
          </a>
          <p className="desc">
            Современное, качественное и функциональное оборудование
          </p>
        </div>
      </div>
    </div>
  </section>
)
