import React from 'react'
import YouTube from 'react-youtube'
 
export default class DevelopmentVideo extends React.Component {
  render() {
    const opts = {
      height: '200',
      width: '100%',
      playerVars: {
        autoplay: 0,
      },
    };
 
    return <YouTube videoId="5JrbBKezh3E" opts={opts} onReady={this._onReady} />;
  }
 
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}