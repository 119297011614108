import React, { Component } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css" // This only needs to be imported once in your app
import Img from "gatsby-image"
import Gallery from "react-grid-gallery"

import styled from "styled-components"

const Item = styled.div`
  padding: 20px;
  cursor: pointer;
`

export default class ImagesGallery extends Component {
  constructor(props) {
    super(props)

    this.images = this.props.images.nodes.map(
      item => item.childImageSharp.fluid.src
    )

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state

    return (
      <div className="container">
        <div className="row">
          {/* <Gallery images={this.images} /> */}

          {this.props.images.nodes.map((image, index) => (
            <div
              key={index}
              className="col-lg-4"
              onClick={() => this.setState({ isOpen: true, photoIndex: index })}
            >
              <Item>
                <Img fluid={image.childImageSharp.fluid} />
              </Item>
            </div>
          ))}
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={this.images[photoIndex]}
            nextSrc={this.images[(photoIndex + 1) % this.images.length]}
            prevSrc={
              this.images[
                (photoIndex + this.images.length - 1) % this.images.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + this.images.length - 1) % this.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.images.length,
              })
            }
          />
        )}
      </div>
    )
  }
}
